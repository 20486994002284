import React from 'react';

interface LongFormQuestionPageProps {
  answer: string;
  onAnswerChange: (answer: string) => void;
  onNextPage: () => void;
  subheading: string;
  isPageValid: boolean;
}

const LongFormQuestionPage: React.FC<LongFormQuestionPageProps> = ({
  answer,
  onAnswerChange,
  onNextPage,
  subheading,
  isPageValid,
}) => {
  return (
    <div className="longform-question-page">
      <h1>The Product-Led Geek Learning Velocity Index ™</h1>
      <h2>{subheading}</h2>
      <p>What is the single biggest obstacle preventing your team from learning faster and leveraging learnings more effectively?</p>
      <textarea
        value={answer}
        onChange={(e) => onAnswerChange(e.target.value)}
        rows={5}
        className="longform-answer"
      />
      <button onClick={onNextPage} disabled={!isPageValid}>Next</button>
      {!isPageValid && <p className="error-message">Please provide an answer of at least 8 characters.</p>}
    </div>
  );
};

export default LongFormQuestionPage;
