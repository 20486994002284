import React, { useState, useEffect } from 'react';
import StartPage from './components/StartPage';
import QuestionPage from './components/QuestionPage';
import EmailPage from './components/EmailPage';
import ResultsPage from './components/ResultsPage';
import { questions } from './data/questions';
import { Scores, calculateScores } from './utils/scoreCalculations';
import './styles.css';
import LongFormQuestionPage from './components/LongFormQuestionPage';
import { supabase } from './supabaseClient';

type PageType = 'start' | 'learning' | 'leverage' | 'longform' | 'email' | 'results';

const App: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<PageType>('start');
  const [answers, setAnswers] = useState<Record<number, number>>({});
  const [email, setEmail] = useState('');
  const [scores, setScores] = useState<Scores | null>(null);
  const [longFormAnswer, setLongFormAnswer] = useState('');
  const [isPageValid, setIsPageValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);

  const handleStartQuiz = () => {
    setCurrentPage('learning');
  };

  const handleAnswerChange = (questionId: number, value: number) => {
    setAnswers(prevAnswers => {
      const newAnswers = {
        ...prevAnswers,
        [questionId]: value
      };
      
      // Immediately check if all questions for the current page are answered
      const relevantQuestions = questions.filter(q => q.category === currentPage.toUpperCase());
      const allAnswered = relevantQuestions.every(q => newAnswers[q.id] !== undefined);
      
      // Update isPageValid state immediately
      setIsPageValid(allAnswered);
      
      return newAnswers;
    });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsEmailValid(validateEmail(newEmail));
  };

  const handleNextPage = () => {
    if (isPageValid) {
      if (currentPage === 'learning') {
        setCurrentPage('leverage');
      } else if (currentPage === 'leverage') {
        setCurrentPage('longform');
      } else if (currentPage === 'longform') {
        setCurrentPage('email');
      }
      setIsPageValid(false); // Reset validation for the next page
    }
  };

  const handleLongFormAnswerChange = (answer: string) => {
    setLongFormAnswer(answer);
    setIsPageValid(answer.length >= 8);
  };

  const validatePage = () => {
    if (currentPage === 'learning' || currentPage === 'leverage') {
      const relevantQuestions = questions.filter(q => q.category === currentPage.toUpperCase());
      const allAnswered = relevantQuestions.every(q => answers[q.id] !== undefined);
      setIsPageValid(allAnswered);
    } else if (currentPage === 'longform') {
      setIsPageValid(longFormAnswer.length >= 8);
    } else {
      setIsPageValid(true);
    }
  };

  const handleSubmit = async () => {
    const result = await saveSurveyResults();
    if (result) {
      console.log("Survey saved with ID:", result.surveyId);
      setScores(result.scores);
      setCurrentPage('results');
    } else {
      console.error("Failed to save survey");
      // Handle error (e.g., show error message to user)
    }
  };

  const getSubheading = () => {
    switch (currentPage) {
      case 'start': return 'Welcome';
      case 'learning': return 'Learning Category';
      case 'leverage': return 'Leverage Category';
      case 'longform': return 'Final Question';
      case 'email': return 'Enter Your Email';
      case 'results': return 'Results';
      default: return '';
    }
  };

  const validateEmail = (email: string): boolean => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const saveSurveyResults = async () => {
    // Create a new survey entry
    const { data: surveyData, error: surveyError } = await supabase
      .from('surveys')
      .insert({})
      .select()
      .single();

    if (surveyError) {
      console.error('Error creating survey:', surveyError);
      return null;
    }

    const surveyId = surveyData.id;

    // Save answers
    const answersToInsert = Object.entries(answers).map(([questionId, selectedIndex]) => {
      const question = questions.find(q => q.id === parseInt(questionId));
      const answerValue = question?.options[selectedIndex]?.value || 0;
      return {
        survey_id: surveyId,
        question_id: parseInt(questionId),
        answer: answerValue
      };
    });

    const { error: answersError } = await supabase
      .from('answers')
      .insert(answersToInsert);

    if (answersError) {
      console.error('Error saving answers:', answersError);
    }

    // Save long form answer
    const { error: longFormError } = await supabase
      .from('long_form_answers')
      .insert({ survey_id: surveyId, answer: longFormAnswer });

    if (longFormError) {
      console.error('Error saving long form answer:', longFormError);
    }

    // Save email
    const { error: emailError } = await supabase
      .from('email_submissions')
      .insert({ survey_id: surveyId, email });

    if (emailError) {
      console.error('Error saving email:', emailError);
    }

    // Calculate scores
    const calculatedScores = calculateScores(
      Object.fromEntries(
        Object.entries(answers).map(([questionId, selectedIndex]) => {
          const question = questions.find(q => q.id === parseInt(questionId));
          return [questionId, question?.options[selectedIndex]?.value || 0];
        })
      ),
      questions
    );

    // Save scores
    const { error: scoresError } = await supabase
      .from('scores')
      .insert({
        survey_id: surveyId,
        total_score: calculatedScores.overallScore,
        learning_score: calculatedScores.learningScore,
        leverage_score: calculatedScores.leverageScore
      });

    if (scoresError) {
      console.error('Error saving scores:', scoresError);
    }

    return { surveyId, scores: calculatedScores };
  };

  return (
    <div className="app-container">
      <div className="content-container">
        {currentPage === 'start' && 
          <StartPage onStart={handleStartQuiz} subheading={getSubheading()} />
        }
        {(currentPage === 'learning' || currentPage === 'leverage') && (
          <QuestionPage
            questions={questions}
            answers={answers}
            onAnswerChange={handleAnswerChange}
            category={currentPage.toUpperCase() as 'LEARNING' | 'LEVERAGE'}
            onNextPage={handleNextPage}
            subheading={getSubheading()}
            isPageValid={isPageValid}  // Add this line
          />
        )}
        {currentPage === 'longform' && (
          <LongFormQuestionPage
            answer={longFormAnswer}
            onAnswerChange={handleLongFormAnswerChange}
            onNextPage={handleNextPage}
            subheading={getSubheading()}
            isPageValid={isPageValid}
          />
        )}
        {currentPage === 'email' && 
          <EmailPage
            email={email}
            onEmailChange={handleEmailChange}
            onSubmit={() => handleSubmit().catch(console.error)}
            subheading={getSubheading()}
            isEmailValid={isEmailValid}
          />
        }
        {(currentPage === 'results' && scores) && 
          <ResultsPage scores={scores} subheading={getSubheading()} longFormAnswer={longFormAnswer} />
        }
      </div>
    </div>
  );
};

export default App;