import React from 'react';

interface StartPageProps {
  onStart: () => void;
  subheading: string;
}

const StartPage: React.FC<StartPageProps> = ({ onStart, subheading }) => {
  return (
    <div className="start-page">
      <h1>The Product-Led Geek Learning Velocity Index ™</h1>
      <h2>{subheading}</h2>
      <button onClick={onStart}>Start Assessment</button>
    </div>
  );
};

export default StartPage;