import { Question } from '../data/questions';

export interface Scores {
  learningScore: number;
  leverageScore: number;
  overallScore: number;
  individualScores: Array<{ question: string; score: number; category: string }>;
}

export const calculateScores = (answers: Record<number, number>, questions: Question[]): Scores => {
  const learningScore = questions
    .filter(q => q.category === "LEARNING")
    .reduce((sum, q) => sum + (answers[q.id] || 0), 0);
  
  const leverageScore = questions
    .filter(q => q.category === "LEVERAGE")
    .reduce((sum, q) => sum + (answers[q.id] || 0), 0);
  
  const overallScore = learningScore + leverageScore;
  
  const individualScores = questions.map(q => ({
    question: `Q${q.id}`,
    score: answers[q.id] || 0,
    category: q.category
  }));

  return { learningScore, leverageScore, overallScore, individualScores };
};