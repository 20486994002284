import React from "react";
import { createRoot } from 'react-dom/client';
import './styles.css';  // Make sure this line is present
import App from "./App";

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);