export interface Question {
    id: number;
    text: string;
    options: { label: string; value: number }[];
    category: "LEARNING" | "LEVERAGE";
  }
  
  export const questions: Question[] = [
    {
      id: 1,
      text: "In the past quarter, how many distinct growth experiments or A/B tests did your team run?",
      options: [
        { label: "8+ experiments", value: 20 },
        { label: "6-7 experiments", value: 15 },
        { label: "4-5 experiments", value: 10 },
        { label: "2-3 experiments", value: 5 },
        { label: "0-1 experiment", value: 0 },
      ],
      category: "LEARNING"
    },
    {
      id: 2,
      text: "How often does your team proactively seek out direct feedback and insights from your users (e.g., through user interviews, surveys, usability testing) even when NOT prompted by a specific problem or experiment?",
      options: [
        { label: "At least weekly", value: 20 },
        { label: "At least bi-weekly", value: 15 },
        { label: "At least monthly", value: 10 },
        { label: "At least quarterly", value: 5 },
        { label: "Less than once a quarter", value: 0 },
      ],
      category: "LEARNING"
    },
    {
      id: 3,
      text: "How often does your team conduct a formal review or analysis to understand the results of growth experiments or initiatives, regardless of whether they were successful or not?",
      options: [
        { label: "After every single experiment or initiative", value: 20 },
        { label: "After most experiments or initiatives", value: 15 },
        { label: "After some experiments or initiatives", value: 10 },
        { label: "Only after major experiments or initiatives", value: 5 },
        { label: "Rarely or never", value: 0 },
      ],
      category: "LEARNING"
    },
    {
      id: 4,
      text: "How long does it typically take for your team to adjust the product growth roadmap after identifying a significant learning (positive or negative)?",
      options: [
        { label: "Less than 1 week", value: 20 },
        { label: "1-2 weeks", value: 15 },
        { label: "2-3 weeks", value: 10 },
        { label: "3-4 weeks", value: 5 },
        { label: "More than 4 weeks", value: 0 },
      ],
      category: "LEARNING"
    },
    {
      id: 5,
      text: "Thinking back to a recent growth experiment that failed, what was your team's primary response?",
      options: [
        { label: "We celebrated the insights gained from the failure.", value: 20 },
        { label: "We conducted a blameless post-mortem to learn.", value: 15 },
        { label: "We documented what happened but didn't analyze it.", value: 10 },
        { label: "We felt discouraged and avoided similar experiments.", value: 5 },
        { label: "We focused on blaming individuals for mistakes.", value: 0 },
      ],
      category: "LEARNING"
    },
    {
      id: 6,
      text: "Think back to the last time someone on your team shared an idea that was risky, unconventional, or potentially wrong. What was the team's reaction?",
      options: [
        { label: "The team was enthusiastic and encouraged further exploration of the idea, even if it might not work.", value: 20 },
        { label: "The team was receptive and open to discussing the idea, but there were some reservations or concerns.", value: 15 },
        { label: "The team listened politely, but the idea wasn't taken seriously or given much consideration.", value: 10 },
        { label: "The team was dismissive or critical of the idea, making the person who shared it feel uncomfortable or hesitant to share again.", value: 5 },
        { label: "No one on our team would ever share a risky or unconventional idea because it's not safe to do so.", value: 0 },
      ],
      category: "LEARNING"
    },
    {
      id: 7,
      text: "In the past quarter, how many growth experiments or initiatives were directly informed by insights from user or customer data (analytics, interviews, heatmaps, observational studies etc.)?",
      options: [
        { label: "8+ initiatives", value: 20 },
        { label: "4-7 initiatives", value: 15 },
        { label: "2-3 initiatives", value: 10 },
        { label: "1 initiative", value: 5 },
        { label: "None", value: 0 },
      ],
      category: "LEVERAGE"
    },
    {
      id: 8,
      text: "How often does your team meet to review learnings (from experiments, wins, losses)?",
      options: [
        { label: "At least weekly", value: 20 },
        { label: "At least every other week", value: 15 },
        { label: "At least monthly", value: 10 },
        { label: "At least quarterly", value: 5 },
        { label: "Less than once a quarter", value: 0 },
      ],
      category: "LEVERAGE"
    },
    {
      id: 9,
      text: "How often do you proactively share your team's growth learnings with other relevant teams (e.g., other growth teams, product, marketing, sales)?",
      options: [
        { label: "At least weekly", value: 20 },
        { label: "At least every other week", value: 15 },
        { label: "At least monthly", value: 10 },
        { label: "At least quarterly", value: 5 },
        { label: "Less than once a quarter", value: 0 },
      ],
      category: "LEVERAGE"
    },
    {
      id: 10,
      text: "Following a successful growth experiment or initiative, on average how quickly do other teams implement the key learnings from that success (e.g. a core product team productising a growth experiment, or a CS team changing their health scoring and approach based on learnings about retention)?",
      options: [
        { label: "Within 2 weeks", value: 20 },
        { label: "Within 1 month", value: 15 },
        { label: "Within 2 months", value: 10 },
        { label: "It takes longer than 2 months", value: 5 },
        { label: "It takes over 6 months or the work is never prioritised / we don't share with other teams", value: 0 },
      ],
      category: "LEVERAGE"
    },
    {
      id: 11,
      text: "Which of the following BEST describes your team's approach to capturing, organising, and retrieving past learnings?",
      options: [
        { label: "We use a dedicated knowledge management tool where learnings are tagged and easily searchable. Relevant past learnings are automatically surfaced when planning new experiments.", value: 20 },
        { label: "We have a designated shared document for recording learnings. It's fairly organised, but finding specific past insights can sometimes be time-consuming.", value: 15 },
        { label: "We capture learnings informally. It's difficult to find specific past insights, so we often rely on memory or recent discussions.", value: 10 },
        { label: "We discuss learnings in meetings, but they are not documented or organised, making it nearly impossible to reference them later.", value: 5 },
        { label: "We don't have any system for capturing, organising, or retrieving past learnings.", value: 0 },
      ],
      category: "LEVERAGE"
    },
    {
      id: 12,
      text: "How often does your team's leadership (manager, senior stakeholders, etc.) actively solicit and engage in discussions about growth learnings from the team as a whole?",
      options: [
        { label: "At least weekly", value: 20 },
        { label: "At least every other week", value: 15 },
        { label: "At least monthly", value: 10 },
        { label: "At least quarterly", value: 5 },
        { label: "Less than once a quarter", value: 0 },
      ],
      category: "LEVERAGE"
    },
  ];