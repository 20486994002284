import React from 'react';

interface EmailPageProps {
  email: string;
  onEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => Promise<void>;
  subheading: string;
  isEmailValid: boolean;
}

const EmailPage: React.FC<EmailPageProps> = ({
  email,
  onEmailChange,
  onSubmit,
  subheading,
  isEmailValid,
}) => {
  return (
    <div className="email-page">
      <h1>The Product-Led Geek Learning Velocity Index ™</h1>
      <h2>{subheading}</h2>
      <p>Please enter your email to receive your results:</p>
      <input
        type="email"
        value={email}
        onChange={onEmailChange}
        className="email-input"
      />
      <button onClick={onSubmit} disabled={!isEmailValid}>
        Submit
      </button>
      {!isEmailValid && email.length > 0 && (
        <p className="error-message">Please enter a valid email address.</p>
      )}
    </div>
  );
};

export default EmailPage;