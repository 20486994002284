import React from 'react';
import { Question } from '../data/questions';
import '../styles.css';

interface QuestionPageProps {
  questions: Question[];
  answers: Record<number, number>;
  onAnswerChange: (questionId: number, value: number) => void;
  category: 'LEARNING' | 'LEVERAGE';
  onNextPage: () => void;
  subheading: string;
  isPageValid: boolean;
}

const QuestionPage: React.FC<QuestionPageProps> = ({
  questions,
  answers,
  onAnswerChange,
  category,
  onNextPage,
  subheading,
  isPageValid,
}) => {
  const filteredQuestions = questions.filter(q => q.category === category);

  const handleAnswerChange = (questionId: number, selectedIndex: string) => {
    onAnswerChange(questionId, Number(selectedIndex));
  };

  return (
    <div className="question-page">
      <h1>The Product-Led Geek Learning Velocity Index ™</h1>
      <h2>{subheading}</h2>
      {filteredQuestions.map((question) => (
        <div key={question.id} className="question">
          <p className="question-text">{question.text}</p>
          <div className="answer-options">
            <select
              className="answer-option"
              value={answers[question.id]?.toString() ?? ''}
              onChange={(e) => handleAnswerChange(question.id, e.target.value)}
            >
              <option value="">Select an option</option>
              {question.options.map((option, index) => (
                <option key={index} value={index.toString()}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      ))}
      <button onClick={onNextPage} disabled={!isPageValid}>
        {category === 'LEARNING' ? 'Next: Leverage' : 'Next: Final Question'}
      </button>
      {!isPageValid && (
        <p className="error-message">
          Please answer all questions before proceeding.
        </p>
      )}
    </div>
  );
};

export default QuestionPage;