import React from 'react';
import { Scores } from '../utils/scoreCalculations';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar } from 'recharts';

interface ResultsPageProps {
  scores: Scores;
  subheading: string;
  longFormAnswer: string;
}

const ResultsPage: React.FC<ResultsPageProps> = ({ scores, subheading, longFormAnswer }) => {
  const getMatrixQuadrant = () => {
    const { learningScore, leverageScore } = scores;
    if (learningScore < 60 && leverageScore < 60) return "Stagnant Learning";
    if (learningScore >= 60 && leverageScore < 60) return "Wasted Learning";
    if (learningScore >= 60 && leverageScore >= 60) return "Activated Learning";
    return "N/A";
  };

  const radarData = scores.individualScores.map((score, index) => ({
    question: `Q${index + 1}`,
    score: score.score,
    fullMark: 20,
  }));

  const questionLabels = [
    "Experiment Frequently",
    "Proactively Discover User & Customer Insights",
    "Consistently Analyse Experiment Outcomes",
    "Rapidly Adapt Execution Based on Learnings",
    "Respond Positively to Failed Experiments",
    "Share Ideas Freely",
    "Make Data-Informed Decisions",
    "Regularly Reflect on Learnings",
    "Proactively Share Learnings Across Teams",
    "Enable Cross-Team Efforts Based on Learnings",
    "Build Effective Knowledge Systems",
    "Involve Leaders in Learning Discussions"
  ];

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p>Learning Score: {payload[0].payload.learningScore}</p>
          <p>Leverage Score: {payload[0].payload.leverageScore}</p>
        </div>
      );
    }
    return null;
  };

  const CustomRadarTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const index = parseInt(payload[0].payload.question.slice(1)) - 1;
      return (
        <div className="custom-tooltip">
          <p>{questionLabels[index]}</p>
          <p>Score: {payload[0].value}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <h1>The Product-Led Geek Learning Velocity Index ™</h1>
      <h2 style={{ color: '#C2FF09' }}>{subheading}</h2>
      <p>Learning Score: {scores.learningScore}</p>
      <p>Leverage Score: {scores.leverageScore}</p>
      <p>Overall Score: {scores.overallScore}</p>
      <p>Matrix Quadrant: {getMatrixQuadrant()}</p>
      
      <div style={{ height: '400px', marginBottom: '40px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              type="number" 
              dataKey="learningScore" 
              name="Learning Score" 
              unit="" 
              domain={[0, 120]} 
              label={{ value: 'Learning Score', position: 'bottom', offset: 0 }}
            />
            <YAxis 
              type="number" 
              dataKey="leverageScore" 
              name="Leverage Score" 
              unit="" 
              domain={[0, 120]} 
              label={{ value: 'Leverage Score', angle: -90, position: 'insideLeft' }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Scatter name="Score" data={[{ learningScore: scores.learningScore, leverageScore: scores.leverageScore }]} fill="#C2FF09" />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
      
      <div style={{ height: '400px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <RadarChart cx="50%" cy="50%" outerRadius="80%" data={radarData}>
            <PolarGrid />
            <PolarAngleAxis
              dataKey="question"
              tickFormatter={(value, index) => questionLabels[index]}
              style={{ fontSize: '10px' }}
            />
            <PolarRadiusAxis angle={30} domain={[0, 20]} />
            <Radar name="Score" dataKey="score" stroke="#C2FF09" fill="#C2FF09" fillOpacity={0.6} />
            <Tooltip content={<CustomRadarTooltip />} />
          </RadarChart>
        </ResponsiveContainer>
      </div>

      <div className="longform-answer-display">
        <h3>Biggest Obstacle to Learning and Leverage:</h3>
        <p>{longFormAnswer}</p>
      </div>
    </div>
  );
};

export default ResultsPage;